import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading as HeadingTitle} from "../../components/misc/Headings";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg";

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`mt-5 bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-end`;

const Image = styled.div`
    ${props => css`background-image: url("${props.imageSrc}");`}
    ${tw`h-96 w-96 bg-cover bg-center rounded-t-lg`}
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-3 font-medium text-gray-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;


export default () => {

    const cards = [
        {
            imageSrc: "/images/blog/practice/atlat_ceo_josua_in_talks_with_deuter_csr_team.png",
            title: "Responsibility Beyond Borders: ORTOVOX and deuter Focus on a Combination of On-Site Trainings and atlat's Digital Tool for Employee Participation in Vietnam",
            description: <>

                In many supply chains, workers' rights are not adequately protected. For example, <b>Vietnam</b> ranks
                above the global average for violations of overtime regulations. This is where grievance mechanisms come
                into play.
                <br/>
                Grievance mechanisms aim to provide workers with the opportunity to assert their rights by reporting
                complaints. However, such mechanisms can only be effective if they are well-known and easily accessible
                to workers. To address this, atlat developed an intuitive <b>digital complaint system</b> that operates
                as an external process. Complaints are directly forwarded to the sourcing company, e.g., in Europe, from
                suppliers in regions like Asia. These <b>external channels act as a backup</b> when internal factory
                procedures are untrustworthy or impractical.
                <br/>
                Factory workers can report issues through an automated chat monitored by the company’s human rights
                officers on a centralized platform. Automated translations reduce language barriers, significantly
                enhancing accessibility. Managing Director and Co-Founder of atlat, Josua Ovari, says:
                <blockquote>
                    "We are proud to simplify collaboration with various stakeholders through our platform and to
                    witness
                    the improvements in the factories that we have already been able to achieve."
                </blockquote>
                Europe’s largest manufacturer of backpacks and sleeping bags, <b>deuter</b>, launched a pilot project in
                2022
                in a small supplier factory in Vietnam. Supported by the Alliance for Sustainable Textiles, the project
                expanded to eight additional factories in Vietnam’s lower supply chain. The initiative was a
                collaboration with the outdoor apparel brand <b>ORTOVOX</b>, international NGO <b>CARE</b>, and
                Vietnamese NGO <b>CDI</b>.
                <br/>
                Bettina Ernst, Corporate Partnership Manager at CARE Germany, shared her perspective on the project:
                <blockquote>"At CARE Germany, our mission is to support people in improving their living conditions. We
                    value the
                    open and direct exchange with the companies in the project and the recognition of our expertise in
                    supply chains in Southeast Asia. This partnership is characterized by mutual trust and a willingness
                    to
                    learn."
                </blockquote>
            </>
        }, {
            imageSrc: "/images/blog/practice/atlat_onsite_worker_training_in_vietnam.png",
            title: "The Best of Both Worlds: On-Site Training Combined with a Digital System for Maximum Impact",
            description: <>
                Training plays a critical role in the success of grievance mechanisms. Workers cannot take action if
                they are <b>unaware of the existence of complaints mechanisms</b>. The project aims to not only enable
                complaints but also <b>raise awareness</b> among both workers and management through targeted training.
                <br/>
                At the start of the project, an initial baseline assessment was conducted using atlat’s survey tool to
                understand how issues were previously handled in the factory. Workers could complete
                the <b>survey</b> on their
                smartphones, even from home, which encouraged more honest responses. Based on the survey, the NGO CDI
                conducted targeted training in the factories.
                <br/>
                Both workers and factory management received training, sometimes jointly and sometimes separately, in a
                safe and supportive environment. The training covered:

                <ul>
                    <li>Workers' rights</li>
                    <li>Basics of social dialogue</li>
                    <li>Prevention of gender-based violence</li>
                    <li>Factory-internal grievance processes (e.g. ombudsperson)</li>
                    <li>Use of atlat’s external digital complaint system</li>
                </ul>
                Educational materials, such as videos, posters, flyers, and digital resources, were provided by atlat.
                Another key component was training participants to become <b>multipliers</b> within the factory,
                allowing them
                to share their knowledge with others.
                <br/>
                In collaboration with CDI, atlat also developed a digital documentation platform to <b>facilitate
                communication after training sessions</b>. CSR managers from deuter and ORTOVOX can monitor training
                progress, including participant lists, issues raised, questions, and images, enabling them to make
                necessary adjustments.
                <br/>
                An Dinh Ha from CDI commented:
                <br/>
                <blockquote>
                    "The use of the tool is helpful for us. It simplifies communication with stakeholders in the project
                    –
                    everything is handled in one place, rather than through numerous emails."
                </blockquote>
            </>
        }, {
            imageSrc: "/images/blog/practice/evaluating_atlat_survey_results_in_vietnam.png",
            title: "Initial Results: Impact of the Measures",
            description: <>
                The <b>German Supply Chain Due Diligence Act (LkSG)</b> and the <b>EU Directive on Corporate Due
                Diligence (CSDDD)</b> require companies to establish grievance mechanisms.
                <br/>
                Robert Hellwig, CSR and Supply Chain Manager at ORTOVOX, shared his pride in the project:
                <blockquote>
                    "We are proud of this project. It impressively demonstrates that regulatory requirements can
                    contribute to the further development of corporate responsibility throughout the entire supply
                    chain."
                </blockquote>
                <br/>
                Atlat’s digital system has already provided measurable insights:
                <ul>
                    <li><b>379 production workers</b> and <b>78 factory management members</b> participated in the
                        initial survey.
                    </li>
                    <li>During the training, <b>52 test complaints</b> were filed and processed.</li>
                    <li>Follow-up training sessions are planned to address any gaps.</li>
                </ul>
                The first complaints are currently being processed, and the project’s expansion to more factories is in
                planning. Nathalie Birke, CSR Manager at deuter Sport GmbH, emphasized the importance of dialogue:
                <blockquote>
                    "The complaint management system gives us the opportunity to identify risks early and further
                    develop our relationships with suppliers. In this way, we can better fulfill our responsibility as a
                    company in the supply chain and actively strengthen the rights of workers."
                </blockquote>
                For an overview of the previous project between atlat and deuter, take a look at last year's Promise
                Report by deuter.
                <br/>
                <a href={"https://www.deuter.com/deuter/documents/social-reports/deuter-Promise-Report_22-23_DE.pdf"}>
                    https://www.deuter.com/deuter/documents/social-reports/deuter-Promise-Report_22-23_DE.pdf
                </a>
                <br/>
                For a short overview of the project, read the supplementary report on the project by the Alliance for
                Sustainable Textiles (BNT):
                <br/>
                <a href="https://www.textilbuendnis.com/buendnisinitiative-digital-complaints-managemen">
                    Alliance for Sustainable Textiles – Digital Complaints Management
                </a>


            </>
        },


    ];

    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <SingleColumn>
                    <HeadingInfoContainer>
                        <HeadingTitle>Supply Chain Act in Practice:
                            Initiative from the Textile Alliance Advances Dialogue in Vietnam
                        </HeadingTitle>
                        <HeadingDescription>
                            Responsibility Beyond Borders: ORTOVOX and deuter Focus on a Combination of On-Site
                            Trainings and atlat's Digital Tool for Employee Participation in Vietnam
                        </HeadingDescription>
                    </HeadingInfoContainer>

                    <Content>
                        {cards.map((card, i) => (
                            <Card key={i} reversed={i % 2 === 1}>
                                <Image imageSrc={card.imageSrc}/>
                                <Details>
                                    <Title>{card.title}</Title>
                                    <Description>{(card.description)}</Description>
                                </Details>
                            </Card>
                        ))}
                    </Content>
                    <HeadingInfoContainer>
                        <PrimaryLink href="https://atlat.de/contact-us">
                            Contact us
                        </PrimaryLink>
                    </HeadingInfoContainer>
                </SingleColumn>

                <SvgDotPattern1/>
                <SvgDotPattern2/>
                <SvgDotPattern3/>
                <SvgDotPattern4/>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
