import React from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
// import Navigation from '../Navigation';
import LandingPage from '../Landing';
import HomePage from '../Home';

import * as ROUTES from '../../constants/routes';
import {withAuthentication} from '../Session';
import AboutUs from "../../pages/AboutUs";
import BlogIndex from "../../pages/blog/BlogIndex";
import WebinarsIndex from "../../pages/webinars/WebinarsIndex";
import ContactUs from "../../pages/ContactUs";
import Pricing from "../../pages/Pricing";
import Products from "../../pages/products/Products";
import PrivacyPolicy from "../../pages/PrivacyPolicy";
import Signup from "../../pages/Signup";
import TermsOfService from "../../pages/TermsOfService";
import GetStarted from "../../pages/GetStarted";
import BlogPost1 from "../../pages/blog/BlogPost1";
import BlogPost2 from "../../pages/blog/BlogPost2";
import CookieConsent from "react-cookie-consent";
import {useTranslation} from "react-i18next";
import BlogPost3 from "../../pages/blog/BlogPost3";
import HumanRightsPolicyGenerator from "../../pages/products/HumanRightsPolicyGenerator2";
import SupplierCommunication from "../../pages/products/SupplierCommunication";
import Dashboard from "../dashboard/Dashboard";
import Handling from "../dashboard/Handling";
import ComplaintMechanism from "../../pages/products/ComplaintMechanism";
import BlogPost4 from "../../pages/blog/BlogPost4";
import BlogPost5 from "../../pages/blog/BlogPost5";
import BlogPost6 from "../../pages/blog/BlogPost6";
import BlogPost7 from "../../pages/blog/BlogPost7";
import BlogPost8 from "../../pages/blog/BlogPost8";
import BlogPost9 from "../../pages/blog/BlogPost9";
import BlogPost10 from "../../pages/blog/BlogPost10";
import BlogPost11 from "../../pages/blog/BlogPost11";
import BlogPost12 from "../../pages/blog/BlogPost12";
import Imprint from "../../pages/Imprint";
import BlogPost13 from "../../pages/blog/BlogPost13";
import BlogPost14 from "../../pages/blog/BlogPost14";
import BlogPost15 from "../../pages/blog/BlogPost15";
import BlogPost16 from "../../pages/blog/BlogPost16";
import BlogPost17 from "../../pages/blog/BlogPost17";
import WebinarRecording1_Hembach from "../../videos/WebinarRecording1_Hembach";
import BlogPost18 from "../../pages/blog/BlogPost18";
import BlogPost19 from "../../pages/blog/BlogPost19";
import WebinarRecording2_DesignForHumanRights from "../../videos/WebinarRecording2_DesignForHumanRights";
import BlogPost20 from "../../pages/blog/BlogPost20";
import BlogPost21 from "../../pages/blog/BlogPost21";
import BlogPost22 from "../../pages/blog/BlogPost22";
import WebinarRecording3_IndustrialUpcycling from "../../videos/WebinarRecording3_IndustrialUpcycling";
import BlogPost23 from "../../pages/blog/BlogPost23";
import WebinarRecording4_SustainableSupplyChains from "../../videos/WebinarRecording4_SustainableSupplyChains";
import BlogPost24 from "../../pages/blog/BlogPost24";
import BlogPost25 from "../../pages/blog/BlogPost25";
import WebinarRecording5_PractitionersInsight from "../../videos/WebinarRecording5_PractitionersInsight";
import BlogPost26 from "../../pages/blog/BlogPost26";
import BlogPost27 from "../../pages/blog/BlogPost27";
import BlogPost28 from "../../pages/blog/BlogPost28";
import WebinarRecording6 from "../../videos/WebinarRecording6";
import BlogPost29 from "../../pages/blog/BlogPost29";
import WebinarRecording7 from "../../videos/WebinarRecording7";
import BlogPost30 from "../../pages/blog/BlogPost30";
import BlogPost31 from "../../pages/blog/BlogPost31";
import AHKTypeformRedirect from "../../pages/AHKTypeformRedirect";
import BlogPost32 from "../../pages/blog/BlogPost32";
import BlogPost33 from "../../pages/blog/BlogPost33";
import BlogPost34 from "../../pages/blog/BlogPost34";
import BlogPost35 from "../../pages/blog/BlogPost35";
import BlogPost36 from "../../pages/blog/BlogPost36";


const App = () => {

        const {t} = useTranslation(['cookies']);
        return (
            <Router>
                <div>
                    {/*<Navigation/>*/}

                    <hr/>
                    <Route exact path={ROUTES.LANDING} component={LandingPage}/>
                    <Route path={ROUTES.HOME} component={HomePage}/>
                    <Route path={ROUTES.ABOUT_US} component={AboutUs}/>
                    <Route path={ROUTES.IMPRINT} component={Imprint}/>
                    <Route path={ROUTES.BLOG} component={BlogIndex}/>
                    <Route path={ROUTES.WEBINARS} component={WebinarsIndex}/>
                    <Route path={ROUTES.WEBINAR_RECORDING_1} component={WebinarRecording1_Hembach}/>
                    <Route path={ROUTES.WEBINAR_RECORDING_2} component={WebinarRecording2_DesignForHumanRights}/>
                    <Route path={ROUTES.WEBINAR_RECORDING_3} component={WebinarRecording3_IndustrialUpcycling}/>
                    <Route path={ROUTES.WEBINAR_RECORDING_4} component={WebinarRecording4_SustainableSupplyChains}/>
                    <Route path={ROUTES.WEBINAR_RECORDING_5} component={WebinarRecording5_PractitionersInsight}/>
                    <Route path={ROUTES.WEBINAR_RECORDING_6} component={WebinarRecording6}/>
                    <Route path={ROUTES.WEBINAR_RECORDING_7} component={WebinarRecording7}/>
                    <Route path={ROUTES.CONTACT_US} component={ContactUs}/>
                    <Route path={ROUTES.GET_STARTED} component={GetStarted}/>
                    <Route path={ROUTES.PRICING} component={Pricing}/>
                    <Route path={ROUTES.PRODUCTS} component={Products}/>
                    <Route path={ROUTES.COMPLAINT_MECHANISM} component={ComplaintMechanism}/>
                    <Route path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicy}/>
                    <Route path={ROUTES.SIGNUP} component={Signup}/>
                    <Route path={ROUTES.TERM_OF_SERVICE} component={TermsOfService}/>
                    <Route path={ROUTES.AHK_TYPEFORM_REDIRECT} component={AHKTypeformRedirect}/>
                    <Route path={ROUTES.BLOG_POST_1} component={BlogPost1}/>
                    <Route path={ROUTES.BLOG_POST_2} component={BlogPost2}/>
                    <Route path={ROUTES.BLOG_POST_3} component={BlogPost3}/>
                    <Route path={ROUTES.BLOG_POST_4} component={BlogPost4}/>
                    <Route path={ROUTES.BLOG_POST_5} component={BlogPost5}/>
                    <Route path={ROUTES.BLOG_POST_6} component={BlogPost6}/>
                    <Route path={ROUTES.BLOG_POST_7} component={BlogPost7}/>
                    <Route path={ROUTES.BLOG_POST_8} component={BlogPost8}/>
                    <Route path={ROUTES.BLOG_POST_9} component={BlogPost9}/>
                    <Route path={ROUTES.BLOG_POST_10} component={BlogPost10}/>
                    <Route path={ROUTES.BLOG_POST_11} component={BlogPost11}/>
                    <Route path={ROUTES.BLOG_POST_12} component={BlogPost12}/>
                    <Route path={ROUTES.BLOG_POST_13} component={BlogPost13}/>
                    <Route path={ROUTES.BLOG_POST_14} component={BlogPost14}/>
                    <Route path={ROUTES.BLOG_POST_15} component={BlogPost15}/>
                    <Route path={ROUTES.BLOG_POST_16} component={BlogPost16}/>
                    <Route path={ROUTES.BLOG_POST_17} component={BlogPost17}/>
                    <Route path={ROUTES.BLOG_POST_18} component={BlogPost18}/>
                    <Route path={ROUTES.BLOG_POST_19} component={BlogPost19}/>
                    <Route path={ROUTES.BLOG_POST_20} component={BlogPost20}/>
                    <Route path={ROUTES.BLOG_POST_21} component={BlogPost21}/>
                    <Route path={ROUTES.BLOG_POST_22} component={BlogPost22}/>
                    <Route path={ROUTES.BLOG_POST_23} component={BlogPost23}/>
                    <Route path={ROUTES.BLOG_POST_24} component={BlogPost24}/>
                    <Route path={ROUTES.BLOG_POST_25} component={BlogPost25}/>
                    <Route path={ROUTES.BLOG_POST_26} component={BlogPost26}/>
                    <Route path={ROUTES.BLOG_POST_27} component={BlogPost27}/>
                    <Route path={ROUTES.BLOG_POST_28} component={BlogPost28}/>
                    <Route path={ROUTES.BLOG_POST_29} component={BlogPost29}/>
                    <Route path={ROUTES.BLOG_POST_30} component={BlogPost30}/>
                    <Route path={ROUTES.BLOG_POST_31} component={BlogPost31}/>
                    <Route path={ROUTES.BLOG_POST_32} component={BlogPost32}/>
                    <Route path={ROUTES.BLOG_POST_33} component={BlogPost33}/>
                    <Route path={ROUTES.BLOG_POST_34} component={BlogPost34}/>
                    <Route path={ROUTES.BLOG_POST_35} component={BlogPost35}/>
                    <Route path={ROUTES.BLOG_POST_36} component={BlogPost36}/>
                    <Route path={ROUTES.PRODUCT_HUMAN_RIGHTS_POLICY_GENERATOR} component={HumanRightsPolicyGenerator}/>
                    <Route path={ROUTES.PRODUCT_SUPPLIER_WORKERS_COMMUNICATION} component={SupplierCommunication}/>
                    <Route path={ROUTES.PRODUCT_COMPLAINTS_DASHBOARD} component={Dashboard}/>
                    <Route path={ROUTES.PRODUCT_COMPLAINT_HANDLING} component={Handling}/>
                    <Route path={ROUTES.PRODUCT_COMPLAINT_HANDLING} component={Handling}/>

                    <CookieConsent
                        location="bottom"
                        buttonText={t('cookies:cookieConsentButton', 'I agree')}
                        cookieName="trueColorsCookieConsent"
                        acceptOnScroll={false}
                        style={{background: "#1b8591"}}
                        buttonStyle={{backgroundColor: "#f56565", color: "#ffffff", fontSize: "13px"}}
                        expires={150}
                        sameSite="lax"
                        cookieSecurity={false}
                    >
                        {t('cookies:cookieConsentText', 'This website uses cookies. By using it, you agree to that.' +
                            ' Learn more: ')}
                        <a href="https://atlat.de/privacy-policy"> {t('cookies:privacyPolicy', 'Privacy Policy')}</a>
                    </CookieConsent>

                </div>
            </Router>
        )
    }
;

export default withAuthentication(App);
