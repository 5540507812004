import React, {useState} from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container, ContentWithPaddingXl} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading} from "../../components/misc/Headings";
import {PrimaryButton} from "../../components/misc/Buttons";
import {
    BLOG_POST_6,
    BLOG_POST_7,
    BLOG_POST_8,
    BLOG_POST_9,
    BLOG_POST_10,
    BLOG_POST_11,
    BLOG_POST_12,
    BLOG_POST_13,
    BLOG_POST_14,
    BLOG_POST_15,
    BLOG_POST_16,
    BLOG_POST_17,
    BLOG_POST_18,
    BLOG_POST_19,
    BLOG_POST_20,
    BLOG_POST_21,
    BLOG_POST_22,
    BLOG_POST_23,
    BLOG_POST_24,
    BLOG_POST_25,
    BLOG_POST_26,
    BLOG_POST_27,
    BLOG_POST_28,
    BLOG_POST_29,
    BLOG_POST_30,
    BLOG_POST_31,
    BLOG_POST_32,
    BLOG_POST_33,
    BLOG_POST_34,
    BLOG_POST_35,
    BLOG_POST_36
} from "../../constants/routes";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900`;
const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;
const PostContainer = styled.div`
    ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
    ${props =>
            props.featured &&
            css`
                ${tw`w-full!`}
                ${Post} {
                    ${tw`sm:flex-row! h-full sm:pr-4`}
                }

                ${Image} {
                    ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
                }

                ${Info} {
                    ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
                }

                ${Description}
                ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
            }
            `}
`;
const Post = tw.div`cursor-pointer flex flex-col bg-gray-100 rounded-lg`;
const Image = styled.div`
    ${props => css`background-image: url("${props.imageSrc}");`}
    ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`;
const Info = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none`;
const Category = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`;
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
const Title = tw.div`mt-1 font-black text-2xl text-gray-900 group-hover:text-primary-500 transition duration-300`;
const Description = tw.div``;

const ButtonContainer = tw.div`flex justify-center`;
const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;

export default ({
                    headingText = "Blog",
                    posts = [
                        {
                            imageSrc: "/images/blog/practice/vietnamese_workers_testing_atlat_chat.png",
                            category: "Customers",
                            date: "29 January 2025",
                            title: "Supply Chain Act in Practice: Initiative from the Textile Alliance Advances Dialogue in Vietnam",
                            description: "Responsibility Beyond Borders: ORTOVOX and deuter Focus on a Combination of On-Site Trainings and atlat's Digital Tool for Employee Participation in Vietnam",
                            url: BLOG_POST_36,
                            featured: true
                        },
                        {
                            imageSrc: "/images/blog/igep/atlat_ceo_josua_ovari_signs_contract_with_igep.png",
                            category: "Customers",
                            date: "29 June 2024",
                            title: "Rock Solid Compliance: IGEP improves standards for working conditions in the Indian gravestone sector",
                            description: " The pilot project with IGEP and atlat aims to improve working conditions in India's\n" +
                                "                            tombstone industry by using digital tools to amplify employees' voices. The cooperation has\n" +
                                "                            been presented at the Stone+tec trade fair in Nuremberg.",
                            url: BLOG_POST_35,
                            featured: false
                        },
                        {
                            imageSrc: "/images/blog/textracer/feedback_good_experience_with_atlat.png",
                            category: "Customers",
                            date: "27 April 2024",
                            title: "Strengthening Worker Voice: Grievance Mechanism and Worker Voice Survey with a client of tex.tracer ",
                            description: "In the collaboration between tex.tracer and atlat, we embarked on a project to support social responsibility by including workers' voices. Methods of evaluating working conditions, like self-assessments, audits, or factory visits, have their strengths, but they merely offer a glimpse into a specific moment. Recognizing these limitations, we took additional steps to enhance our approach. Alongside these methods, we conducted a worker voice survey and established a grievance mechanism with the goal of gathering genuine insights, resolving issues, and shaping long-term supplier relationships. Already, we’re witnessing positive outcomes as the survey insights enabled constructive discussions between factory management and CSR team. This project highlights our shared dedication with tex.tracer to foster social responsibility within the textile sector.",
                            url: BLOG_POST_34,
                            featured: false
                        },
                        {
                            imageSrc: "/images/blog/csddd_in_danger/ceo_atlat_josua_ovari_quote.png",
                            category: "Legislation",
                            date: "8 February 2024",
                            title: "The Shocking Twist in Germany's Stand on EU Supply Chain Law",
                            description: "Germany's recent announcement of abstaining from the CSDDD Corporate Sustainability Due Diligence Directive, also know as EU Supply Chain Law, has sent shockwaves across the European Union. The decision, revealed on February 7, 2024, has left many puzzled and concerned about the implications for human rights and environmental protection. Let's delve into the heart of the matter and explore the key arguments surrounding this unexpected move.",
                            url: BLOG_POST_32,
                            featured: false
                        },

                        {
                            imageSrc: "/images/blog/deuter_vietnam/Poster_Workers_Discussion_Vietnam.png",
                            category: "Partner",
                            date: "8 December 2022",
                            title: "Cooperation with deuter in Vietnam",
                            description: "We are more than proud to announce: Our software is live in Vietnam! The backpack and sleeping bag manufacturer deuter uses the digital complaints system by atlat in factories in Vietnam. 🎉",
                            url: BLOG_POST_22,
                            featured: true
                        },
                        {
                            imageSrc: "/images/blog/csddd_in_danger/ceo_atlat_josua_ovari_quote.png",
                            category: "Legislation",
                            date: "8. Februar 2024",
                            title: "Die Schockierende Wendung in Deutschlands Haltung zum EU-Lieferkettengesetz",
                            description: "Die kürzliche Ankündigung Deutschlands, sich beim EU-Lieferkettengesetz zu enthalten, hat\n" +
                                "                            eine Schockwelle durch die Europäische Union geschickt. Die Entscheidung, die am 7. Februar\n" +
                                "                            2024 bekannt gegeben wurde, hat viele verwirrt und Sorgen über die Zuverlässigkeit der\n" +
                                "                            deutschen Bundesregierung und über die Auswirkungen auf Menschenrechte und Umweltschutz\n" +
                                "                            geweckt. Lassen Sie uns zum Kern der Sache vordringen und die wichtigsten Argumente rund um\n" +
                                "                            diese unerwartete Wendung erkunden.",
                            url: BLOG_POST_33,
                            featured: false
                        },
                        {
                            imageSrc: "/images/blog/research_portugal/user_research_atlat_portugal.png",
                            category: "User Research",
                            date: "16 August 2023",
                            title: "Research in Portugal with Workers in a Garment Factory: Exploring a Digital Grievance Mechanism",
                            description: "We summarized our insights from a research study conducted in a Portuguese garment factory, uncovering insights into the efficacy of our complaints chatbot, workers' feedback, and other grievance possibilities.",
                            url: BLOG_POST_31,
                            featured: false
                        },
                        {
                            imageSrc: "/images/blog/ispo_outdoor/atlat_ceo_networks_at_ispo_outdoor_fair.jpg",
                            category: "Trade Fair",
                            date: "14 June 2023",
                            title: "Reflections on the OutDoor by ISPO",
                            description: "The OutDoor by ISPO in Munich showcased the importance of collaboration and sustainability in the outdoor industry. However, amidst the positive developments, it's crucial to critically examine green claims.",
                            url: BLOG_POST_30,
                            featured: false
                        }, {
                            imageSrc: "/images/blog/csd3/eu-parliament-celebrates-csd3.png",
                            category: "Legislation",
                            date: "3 June 2023",
                            title: "BIG MILESTONE FOR EU MANDATORY HUMAN RIGHTS AND ENVIRONMENTAL DUE DILIGENCE",
                            description: "Yesterday, the European Parliament approved the text for the draft Corporate Sustainability Due Diligence Directive (#CSDDD), which would require certain companies to conduct due diligence to identify, assess and address human rights and environmental harm. The final text will now be negotiated through a trilateral process between the Parliament, Council and Commission. 🗳️ Vote: 366👍 225👎 38⭕️",
                            url: BLOG_POST_29,
                            featured: false
                        }, {
                            imageSrc: "/images/blog/news_eu_due_diligence_directive/whats-in-the-proposal-for-the-european-supply-chain-law.png",
                            category: "Legislation",
                            date: "27 April 2023",
                            title: "News from the EU Corporate Sustainability Due Diligence Directive",
                            description: "The Legal Affairs Committee of the European Parliament has today adopted its position on the EU Supply Chain Law. The Committee's proposals are considered groundbreaking for the EU Supply Chain Law vote in the European Parliament on June 1.",
                            url: BLOG_POST_28,
                            featured: false
                        }, {
                            imageSrc: "/images/blog/rana_place_remembrance/justice-is-everybodys-business.png",
                            category: "Remembrance",
                            date: "24 April 2023",
                            title: "Rana Plaza Remembrance",
                            description: "Ten years ago, on April 24, 2013, the Rana Plaza factory in Bangladesh collapsed, killing over 1137 people and injuring thousands more. In the decade since the tragedy, efforts have been made to improve safety in the garment industry, including the Accord on Fire and Building Safety in Bangladesh. Just a few months earlier, the Tazreen Fashion factory had caught fire, killing over 100 workers. These tragedies put a spotlight on the unsafe working conditions and low wages in the garment industry. It surely served as a turning point for the textile and garment industry.",
                            url: BLOG_POST_27,
                            featured: false
                        }, {
                            imageSrc: "/images/blog/deuter_visit/atlat-ceos-meet-deuter-csr-team.jpeg",
                            category: "Customers",
                            date: "19 April 2023",
                            title: "PROJECT APPROVAL AND VISITING THE DEUTER HQ IN GERSTHOFEN",
                            description: "We had the opportunity to visit the headquarters of deuter in Gersthofen. Our joint project proposal titled \"Digital Complaint Management and Capacity Building\" by deuter Sport GmbH, Ortovox Sportartikel GmbH, CARE Deutschland e.V., and CARE in Vietnam has been approved by the Partnership for Sustainable Textiles by GIZ and BMZ. What a great opportunity!",
                            url: BLOG_POST_26,
                            featured: false
                        }, {
                            imageSrc: "/images/blog/fashion_for_change_vilnius/atlat-ceo-josua-with-nikki-from-tex-tracer.png",
                            category: "Accelerator",
                            date: "21 March 2023",
                            title: "FASHION FOR CHANGE PITCH IN VILNIUS",
                            description: "Last week was the final Demo Day Pitch of the Fashion For Change EU accelerator program where we had the chance to pitch our collaboration with tex.tracer and meet exciting textile startups, fair fashion experts and investors.",
                            url: BLOG_POST_25,
                            featured: false
                        }, {
                            imageSrc: "/images/blog/oecd_garment_forum_paris/oecd-forum-paris-conference-center.jpg",
                            category: "Conference",
                            date: "28 February 2023",
                            title: "OECD Responsible Supply Chains Forum 2023 in Paris",
                            description: "Last week our co-founder Josua had the incredible chance to participate in the 9th OECD Due Diligence Guidance for Responsible Supply Chains in the Garment and Footwear Sector Forum 2023 in Paris, which brought together workers' representatives, trade unions, businesses, and policymakers with close to 400 participants in Paris and over 1000 more joining online to discuss responsible business conduct, working conditions, and supply chain due diligence in the fashion sector.",
                            url: BLOG_POST_24,
                            featured: false
                        }, {
                            imageSrc: "/images/blog/neonyt/neonyt_goes_duesseldorf_2023.png",
                            category: "Conference",
                            date: "4 February 2023",
                            title: "NEONYT Fair Fashion in Düsseldorf",
                            description: "The Neonyt fair fashion in Düsseldorf at AREAL BÖHLER - Der Innovations- und Messestandort was an outstanding event that brought together key players in the fashion industry to showcase sustainable and ethical fashion options. The event highlighted the importance of #fairfashion, which prioritizes the well-being of workers and the environment. The influence of fair fashion on consumers is growing, as more people become conscious of the impact of their clothing choices.",
                            url: BLOG_POST_23,
                            featured: false
                        }, {
                            imageSrc: "/images/blog/ispo2022/josua-and-hauke-from-atlat-at-ispo-2022.jpeg",
                            category: "Trade Fair",
                            date: "30 November 2022",
                            title: "Visiting the ISPO",
                            description: "We had the chance to visit the ISPO, learn a lot about industry innovations, and meet a lot of great people passionate about sports, textiles, and the outdoors. 🎿",
                            url: BLOG_POST_21,
                            featured: false
                        }, {
                            imageSrc: "/images/blog/hackathon_expert_ai/hackathon-expert-ai-banner.png",
                            category: "Hackathon",
                            date: "28 November 2022",
                            title: "Winner: Turn Language into Action",
                            description: "Great news: Team atlat got awarded the second prize out of 520 participants at the “NLP hackathon for Good”! 🎉",
                            url: BLOG_POST_20,
                            featured: false
                        }, {
                            imageSrc: "/images/blog/fashion_changers/fashion-changers-conference-banner.png",
                            category: "Conference",
                            date: "20 November 2022",
                            title: "Fashion Changers Conference 2022",
                            description: "We had the chance to take part in the Fashion Changers Conference 2022.💛",
                            url: BLOG_POST_19,
                            featured: false
                        }, {
                            imageSrc: "/images/blog/push/atlat-founder-josua-at-push-ux-conference.jpg",
                            category: "Conference",
                            date: "10 November 2022",
                            title: "Exhibition at PUSH UX conference 2022",
                            description: "We had the privilege to contribute to the exhibition at PUSH UX conference 2022, the 10 years anniversary of the 2-days conference.💜",
                            url: BLOG_POST_18,
                            featured: false
                        }, {
                            imageSrc: "/images/blog/huge_thing_demo_day_warsaw/huge-thing-google-for-startups-people-demo-day.jpg",
                            category: "Event",
                            date: "23 October 2022",
                            title: "Huge Thing & Google for Startups Demo Day in Warsaw",
                            description: "We presented atlat at Google Campus in Warsaw at the Huge Thing & Google for Startups Pre-Acceleration Demo-Day! 🚀",
                            url: BLOG_POST_17,
                            featured: false
                        }, {
                            imageSrc: "/images/blog/ffc_kickoff/collaboration-between-atlat-and-tex-tracer.jpg",
                            category: "Event",
                            date: "17 October 2022",
                            title: "Fashion For Change EU Accelerator Kick-Off in Tallinn",
                            description: "The Fashion4change EU accelerator program supports SME’s, fashion studios, and start-ups, to work on innovation for a more sustainable, more social, and more circular fashion industry.",
                            url: BLOG_POST_16,
                            featured: false
                        }, {
                            imageSrc: "/images/blog/eu supply chain law/flag-of-the-european-union.jpg",
                            category: "Legal",
                            date: "05 March 2022",
                            title: "Proposal for European Due Diligence Regulation published: Which companies will be affected?",
                            description: "The EU Commission published a proposal for a “European due diligence law”. Which companies are in scope will also depend on their industry sector.",
                            url: BLOG_POST_15,
                            featured: false
                        },
                        {
                            imageSrc: "/images/blog/happynewyear/atlat-rewind-2021.jpeg",
                            category: "NEW YEAR",
                            date: "13 Januar 2022",
                            title: "HAPPY NEW YEAR!",
                            description: "Thank you for accompanying us on our journey so far. We look forward to creating an impact for better working conditions in supply chains all around the globe! We wish you a happy new year! 🎉",
                            url: BLOG_POST_14,
                            featured: false
                        },
                        {
                            imageSrc: "/images/blog/human_rights_day/article-25-declaration-of-human-rights.jpg",
                            category: "Event",
                            date: "10 December 2021",
                            title: "Human Rights Day",
                            description: "On December 10, 1948, the Universal Declaration of Human Rights was promulgated by the General Assembly of the United Nations. Human rights organizations all over the world dedicate this day annually to remind that these rights are violated again and again around the globe. ⚖️",
                            url: BLOG_POST_13,
                            featured: false
                        },
                        {
                            imageSrc: "/images/blog/gruendungspreis/josua-from-atlat-presenting-during-bmwi-gruendungspreis.jpeg",
                            category: "Event",
                            date: "24 November 2021",
                            title: "Founding price of the BMWi",
                            description: "Of 388 start-up ideas for digital innovations from Germany that were" +
                                " submitted, our startup was rated as one of the best.🤩\nWe are very happy about" +
                                " this founding award from the BMWi! 🥳",
                            url: BLOG_POST_12,
                            featured: false
                        },
                        {
                            imageSrc: "/images/blog/gruendungspreis/atlat-team-happy-about-winning-during-bmwi-gruendungspreis-finale.png",
                            category: "Event",
                            date: "24 November 2021",
                            title: "Gründungspreis des BMWi",
                            description: "Von 388 eingereichten Gründungsideen für digitale Innovationen aus Deutschland wurde unser Startup als eines der besten bewertet.🤩\n" +
                                " Wir freuen uns sehr über diesen Gründungspreis des BMWi! 🥳",
                            url: BLOG_POST_11,
                            featured: false
                        },
                        {
                            imageSrc: "/images/blog/atlat-team-celebrates-third-place-in-european-impact-hero-finale.png",
                            category: "Event",
                            date: "11 November 2021",
                            title: "atlat places third in European Impact Hero Finale",
                            description: "We are happy to announce that we are amongst the winners of the European " +
                                "Impact Hero Day.🥳\n We made third place at the competition after a six-month journey" +
                                " of learning and growing.",
                            url: BLOG_POST_10,
                            featured: false
                        }, {
                            imageSrc: "/images/blog/the-worthy-web-app-challenge-by-progress.png",
                            category: "Event",
                            date: "9 June 2021",
                            title: "The Worthy Web App Challenge by Progress",
                            description: "Proud to announce: We are the winner of “THE WORTHY WEB APP CHALLENGE” by " +
                                "Progress Telerik in the main category “Best Use of KendoReact” and by that won 7.500" +
                                " USD.🥳\nThe hackathon took place from Apr 7 – May 24, 2021 and counted 1174 participants.",
                            url: BLOG_POST_9,
                            featured: false
                        }, {
                            imageSrc: "/images/blog/the-atlat-team-celebrates-winning-at-european-impact-day.png",
                            category: "Event",
                            date: "28 May 2021",
                            title: "European Impact Hero Day",
                            description: "We are happy to announce that we are amongst the winners of the European" +
                                " Impact Hero Day.🥳 \n" +
                                "This means we are part of the Impact Hero Lab, a six-month digital programme that" +
                                " provides individual support.",
                            url: BLOG_POST_8,
                            featured: false
                        },
                        {
                            imageSrc: "https://images.unsplash.com/photo-1574871790477-55b0318b599b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
                            category: "Fair Fashion",
                            date: "24 April 2021",
                            title: "Fashion Revolution",
                            description: "On the anniversary of the Rana Plaza tragedy, the Fashion Revolution Day marks the time when people from all " +
                                "over the world come together to contribute to a better fashion industry. Today" +
                                " marks its 8th anniversary.",
                            url: BLOG_POST_7,
                            featured: false
                        },
                        {
                            imageSrc: "https://images.unsplash.com/photo-1584962119638-92a71b026dee?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
                            category: "Event",
                            date: "11 April 2021",
                            title: "True colors at GREENSTYLE munich",
                            description:
                                "Last week True Colors participated at an amazing remote event: The GREENSTYLE" +
                                " munich responsible fashion summit, supported by Wildling barefoot shoes.",
                            url: BLOG_POST_6,
                            featured: false
                        },
                        {
                            imageSrc: "/images/true_colors_gewinnt_tchibo_hackathon.jpg",
                            category: "Lieferkettengestz",
                            date: "04. März 2021",
                            title: "True Colors gewinnt Tchibo Hackathon",
                            description:
                                "True Colors ist der Gewinner des Tchibo THE NEXT DIGITAL LEVEL HACKATHON 2021 in" +
                                " der Kategorie “THINK BIG SOLUTION”.🥳",
                            url: "true-colors-gewinnt-tchibo-hackathon",
                            featured: false
                        },
                        {
                            imageSrc: "/images/true_colors_gewinnt_tchibo_hackathon.jpg",
                            category: "Lieferkettengestz",
                            date: "04 March 2021",
                            title: "True Colors wins Tchibo Hackathon",
                            description:
                                "True Colors is the winner of the Tchibo THE NEXT DIGITAL LEVEL HACKATHON 2021 in" +
                                " the category “THINK BIG SOLUTION”.🥳",
                            url: "true-colors-wins-tchibo-hackathon",
                            featured: false
                        },
                        {
                            imageSrc:
                                "https://images.unsplash.com/photo-1518181835702-6eef8b4b2113?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
                            category: "Lieferkettengestz",
                            date: "5. Oktober 2020",
                            title: "Kommunikation entlang der Lieferkette einfach gemacht",
                            description:
                                "Beschwerdemechanismen für Beschäftigte bei Zulieferern werden ein Bestandteil des  Sorgfaltspflichtengesetz. True Colors bietet eine einfach zu nutzende Softwarelösung an.",
                            url: "https://atlat.de/kommunikation-entlang-der-lieferkette-leicht-gemacht",
                            featured: false
                        },
                        {
                            imageSrc:
                                "https://images.unsplash.com/photo-1434626881859-194d67b2b86f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
                            category: "Lieferkettengestz",
                            date: "1. September 2020",
                            title: "Entwurf für Lieferkettengesetz sieht Pflicht zum Sorgfaltsplan vor",
                            description:
                                "Es wird im Bundestag heiß diskutiert. Es ist noch nicht abschließend debattiert wie" +
                                " streng die Regeln im Detail werden, aber es ist klar: Das Lieferkettengesetz" +
                                " kommt. Was heißt das für Sie und Ihr Unternehmen? Haben Sie Sich schon Gedanken" +
                                " darüber gemacht, wie Sie ...",
                            url: "https://atlat.de/sorgfaltsplan-erstellen-lieferkettengesetz",
                            featured: false
                        },
                        {
                            imageSrc: "/images/trees_and_lake.jpg",
                            category: "sustainability",
                            date: "25. September 2020",
                            title: "Why Sustainable Supply Chain is Good for Companies?",
                            description: "In recent years, there has been a renewed focus on businesses turning\n" +
                                "toward a sustainable supply chain. The emphasis on sustainable practices is\n" +
                                "not new, with many governments and industry compliances already in place.\n" +
                                "However, today the consumers themselves are demanding companies to be\n" +
                                "more sustainable in their approach.",
                            url: "https://atlat.de/why-sustainable-supply-chain-is-good-for-companies",
                            featured: false
                        },

                    ]
                }) => {
    const [visible, setVisible] = useState(8);
    const onLoadMoreClick = () => {
        setVisible(v => v + 6);
    };
    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <ContentWithPaddingXl>
                    <HeadingRow>
                        <Heading>{headingText}</Heading>
                    </HeadingRow>
                    <Posts>
                        {posts.slice(0, visible).map((post, index) => (
                            <PostContainer key={index} featured={post.featured}>
                                <Post className="group" as="a" href={post.url}>
                                    <Image imageSrc={post.imageSrc}/>
                                    <Info>
                                        <Category>{post.category}</Category>
                                        <CreationDate>{post.date}</CreationDate>
                                        <Title>{post.title}</Title>
                                        {post.featured && post.description &&
                                            <Description>{post.description}</Description>}
                                    </Info>
                                </Post>
                            </PostContainer>
                        ))}
                    </Posts>
                    {visible < posts.length && (
                        <ButtonContainer>
                            <LoadMoreButton onClick={onLoadMoreClick}>Load More</LoadMoreButton>
                        </ButtonContainer>
                    )}
                </ContentWithPaddingXl>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
