export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/pw-forget';
export const ADMIN_DETAILS = '/admin/:id';

export const IMPRINT = '/imprint';
export const ABOUT_US = '/about-us';
export const BLOG = '/blog';
export const WEBINARS = '/webinars';
export const WEBINAR_RECORDING_1 = '/webinar-recording-hembach-1';
export const WEBINAR_RECORDING_2 = '/webinar-recording-design-for-human-rights';
export const WEBINAR_RECORDING_3 = '/webinar-recording-industrial-upcycling';
export const WEBINAR_RECORDING_4 = '/webinar-recording-startups-for-sustainable-fashion-supply-chains';
export const WEBINAR_RECORDING_5 = '/webinar-recording-insights-grievance-handling';
export const WEBINAR_RECORDING_6 = '/webinar-recording-ensuring-health-and-safety-at-work';
export const WEBINAR_RECORDING_7 = '/webinar-recording-holger-hembach-hrdd-regulations';
export const CONTACT_US = '/contact-us';
export const GET_STARTED = '/get-started';
export const PRICING = '/pricing';
export const PRODUCTS = '/products';
export const COMPLAINT_MECHANISM = '/complaint-mechanism';
export const PRIVACY_POLICY = '/privacy-policy';
export const SIGNUP = '/sign-up';
export const TERM_OF_SERVICE = '/terms-of-service';
export const AHK_TYPEFORM_REDIRECT = '/ahk';

export const BLOG_POST_1 = '/sorgfaltsplan-erstellen-lieferkettengesetz';
export const BLOG_POST_2 = '/kommunikation-entlang-der-lieferkette-leicht-gemacht';
export const BLOG_POST_3 = '/why-sustainable-supply-chain-is-good-for-companies';
export const BLOG_POST_4 = '/true-colors-gewinnt-tchibo-hackathon';
export const BLOG_POST_5 = '/true-colors-wins-tchibo-hackathon';
export const BLOG_POST_6 = '/true-colors-at-GREENSTYLE-munich';
export const BLOG_POST_7 = '/rana-plaza-anniversary-fashion-revolution';
export const BLOG_POST_8 = '/true-colors-wins-on-european-impact-hero-day';
export const BLOG_POST_9 = '/true-colors-wins-worthy-webapp-challenge-by-progress';
export const BLOG_POST_10 = '/atlat-places-third-in-european-impact-hero-finale';
export const BLOG_POST_11 = '/atlat-gewinnt-gruendungspreis-des-bmwi';
export const BLOG_POST_12 = '/atlat-wins-founding-price-of-bmwi';
export const BLOG_POST_13 = '/atlat-honours-human-rights-day';
export const BLOG_POST_14 = '/atlat-wishes-you-a-happy-new-year';
export const BLOG_POST_15 = '/european-due-diligence-atlat-checks-which-companies-are-affected';
export const BLOG_POST_16 = '/Fashion-For-Change-EU-Accelerator-Kick-Off-in-Tallinn';
export const BLOG_POST_17 = '/atlat-at-huge-thing-google-for-startups-demo-day-warsaw';
export const BLOG_POST_18 = '/atlat-at-exhibition-at-push-ux-conference-2022';
export const BLOG_POST_19 = '/atlat-at-the-fashion-changers-conference-2022';
export const BLOG_POST_20 = '/atlat-wins-expert-ai-turn-language-into-action-hackathon-2022';
export const BLOG_POST_21 = '/atlat-at-ispo-2022';
export const BLOG_POST_22 = '/atlat-cooperates-with-deuter-in-vietnam';
export const BLOG_POST_23 = '/atlat-meets-textracer-at-neonyt';
export const BLOG_POST_24 = '/atlat-ceo-josua-visits-oecd-garment-forum-paris';
export const BLOG_POST_25 = '/atlat-pitch-fashion-for-change-vilnius';
export const BLOG_POST_26 = '/atlat-ceos-visit-deuter-hq-in-gersthofen';
export const BLOG_POST_27 = '/rana-plaza-remembrance';
export const BLOG_POST_28 = '/news-from-the-eu-corporate-sustainability-due-diligence-directive';
export const BLOG_POST_29 = '/csd3';
export const BLOG_POST_30 = '/ispo-outdoor';
export const BLOG_POST_31 = '/atlat-user-research-in-portugal';
export const BLOG_POST_32 = '/shocking-twist-germany-csddd';
export const BLOG_POST_33 = '/schockierende-wendung-deutschland-eu-lieferkettengesetz';
export const BLOG_POST_34 = '/grievance_mechanism_worker_survey_tex_tracer';
export const BLOG_POST_35 = '/rock_solid_compliance_atlat_igep_project';
export const BLOG_POST_36 = '/textile_alliance_initiative_with_deuter_ortovox_and_atlat_in_vietnam';

export const PRODUCT_HUMAN_RIGHTS_POLICY_GENERATOR = '/human-rights-policy-generator';
export const PRODUCT_SUPPLIER_WORKERS_COMMUNICATION = '/communication-and-training-for-suppliers-workers';
export const PRODUCT_COMPLAINTS_DASHBOARD = '/complaints-dashboard';
export const PRODUCT_COMPLAINT_HANDLING = '/complaint/handling';
